
import * as _ from 'lodash';
export class Category {
    category_id: String;
    category_type: String;
    color: String;
    created_date: Date;
    description: String;
    enterprise_id: String;
    modified_date: Date;
    name: String;
    user_id: String;
    
    id?: String;
    label?: String;
    actions?:any[] = [];
    quickAction?: '';
					
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
                this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }

        this.id = this.category_id;
        this.label = this.name;
    }
}