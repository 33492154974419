import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { MbscModule } from '@mobiscroll/angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalService } from './global/app.global.service';
import { EnterpriseService } from './services/enterprise.service';
import { GeolocationService } from './services/geolocation.service';
import {ShareService} from './services/share.service'
import { AutoLogoutService } from './services/AutoLogoutService';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './secure/home';
import { AppComponent } from './app.component';
import { AuthGuard } from './helpers/auth.guard';
import { HttpInterceptors } from './helpers/http.interceptors';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { TrendComponent } from './secure/trend/trend.component';
import { ChangepasswordComponent } from './secure/account/changepassword/changepassword.component';
import { CreateComponent } from './secure/home/create/create.component';
import { FeaturesComponent } from './secure/features/features.component';
import { SettingsComponent } from './secure/settings/settings.component';
import { EnterpriseHomeComponent } from './secure/enterprise/enterprise-home/enterprise-home.component';
import { GoalsComponent } from './secure/enterprise/goals/goals.component';
import { ObjectivesComponent } from './secure/enterprise/objectives/objectives.component';
import { TeamsComponent } from './secure/enterprise/teams/teams.component';
import { EnterpriseSettingsComponent } from './secure/enterprise/enterprise-settings/enterprise-settings.component';
import { IndividualComponent } from './secure/enterprise/individual/individual.component';
import { IndividualCreateComponent } from './secure/enterprise/individual/individual-create/individual-create.component';
import { TeamCreateComponent } from './secure/enterprise/teams/team-create/team-create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { EnterpriseInfoComponent } from './secure/enterprise/enterprise-info/enterprise-info.component';
import { ReportsComponent } from './secure/enterprise/reports/reports.component';
import { GoalsCreateComponent } from './secure/enterprise/goals/goals-create/goals-create.component';
import { ObjectivesCreateComponent } from './secure/enterprise/objectives/objectives-create/objectives-create.component';
import { GoalsActivityComponent } from './secure/enterprise/goals/goals-activity/goals-activity.component';
import { ObjectivesActivityComponent } from './secure/enterprise/objectives/objectives-activity/objectives-activity.component';
import { TeamInfoComponent } from './secure/enterprise/team-info/team-info.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ActionCategoriesComponent } from './secure/enterprise/action-categories/action-categories.component';
import { CreateCategoriesComponent } from './secure/enterprise/action-categories/create-categories/create-categories.component';
import { GuidingPrinciplesComponent } from './secure/enterprise/guiding-principles/guiding-principles.component';
//import { CompassComponent } from './secure/enterprise/compass/compass.component';
import { ProgressTrackerComponent } from './secure/enterprise/progress-tracker/progress-tracker.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TeamMemberSelectComponent } from './secure/enterprise/progress-tracker/team-member-select/team-member-select.component';
import { DataTableComponent } from './secure/enterprise/progress-tracker/data-table/data-table.component';
import { GroupedTableComponent } from './secure/enterprise/progress-tracker/grouped-table/grouped-table.component';
import { ColorPickerModule} from 'ngx-color-picker';
import { PulseComponent } from './secure/enterprise/pulse/pulse.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ShareComponent } from './secure/enterprise/progress-tracker/share/share.component';
import { PulseQestionComponent } from './secure/enterprise/pulse-qestion/pulse-qestion.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UpdateQsnComponent } from './secure/enterprise/pulse-qestion/update-qsn/update-qsn.component';
import { EventBrokerModule } from 'ng-event-broker';
import { ActionHelpComponent } from './secure/enterprise/actions/action-help/action-help.component';
import { LogComponent } from './secure/enterprise/pulse-qestion/log/log.component';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';
import { ProgressHelpComponent } from './secure/enterprise/progress-tracker/progress-help/progress-help.component';
import { Angulartics2Module } from 'angulartics2';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SlimLoadingBarModule } from '@cime/ngx-slim-loading-bar';
import { RootStoreModule } from './store/root-store.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MilestoneCreateComponent } from './secure/enterprise/goals/milestone-create/milestone-create.component';
import { PrivacyConsentComponent } from './modals/privacy-consent/privacy-consent.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BdcWalkModule } from 'bdc-walkthrough';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        TrendComponent,
        ChangepasswordComponent,
        CreateComponent,
        FeaturesComponent,
        SettingsComponent,
        EnterpriseHomeComponent,
        GoalsComponent,
        ObjectivesComponent,
        TeamsComponent,
        EnterpriseSettingsComponent,
        IndividualComponent,
        IndividualCreateComponent,
        TeamCreateComponent,
        EnterpriseInfoComponent,
        ReportsComponent,
        GoalsCreateComponent,
        MilestoneCreateComponent,
        ObjectivesCreateComponent,
        GoalsActivityComponent,
        ObjectivesActivityComponent,
        TeamInfoComponent,
        ActionCategoriesComponent,
        CreateCategoriesComponent,
        GuidingPrinciplesComponent,
        // CompassComponent,
        ProgressTrackerComponent,
        TeamMemberSelectComponent,
        DataTableComponent,
        GroupedTableComponent,
        PulseComponent,
        ShareComponent,
        PulseQestionComponent,
        UpdateQsnComponent,
        ActionHelpComponent,
        LogComponent,
        DeleteModalComponent,
        ProgressHelpComponent,
        PrivacyConsentComponent
    ],
    imports: [
        BrowserModule,
        NgxPubSubModule,
        MbscModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        RootStoreModule,
        SlimLoadingBarModule.forRoot(),
        NgApexchartsModule,
        PipesModule,
        // FontAwesomeModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        PaginationModule.forRoot(),
        TypeaheadModule.forRoot(),
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        //CalendarNewPageModule,
        ColorPickerModule,
        CommonModule,
        DragDropModule,
        NgSelectModule,
        NgxSliderModule,
        MatTabsModule,
        MatSelectModule,
        MatMenuModule,
        MatAutocompleteModule,
        NgxPaginationModule,
        EventBrokerModule,
        NgxDatatableModule,
        GooglePlaceModule,
        MatDatepickerModule,
        MatMomentDateModule,
        DirectivesModule,
        BdcWalkModule,
        Angulartics2Module.forRoot({
            gst: {
                trackingIds: ['G-QEQ9QYZJ6D'],
            }
        }),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptors, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        GlobalService,
        EnterpriseService,
        DatePipe,
        GeolocationService,
        ShareService,
        AutoLogoutService,
        AuthGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
