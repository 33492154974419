import { UserItem } from './../../models/user';
import { UserCollectionService } from './user.service';
import { GlobalService } from './../../global/app.global.service';

import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher } from '@ngrx/data';

import { Observable, of, from, forkJoin, throwError, combineLatest } from 'rxjs';
import { map, catchError, mergeMap, switchMap, filter } from 'rxjs/operators';

import { Router } from '@angular/router';
import { sortComparerName } from '../entity/entity-metadata';
import { EnterpriseService } from '../../services';
import { Team } from './../../models/team';


@Injectable()
export class TeamDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private userService: UserCollectionService, private authService: AuthCollectionService ) {
    super('Team', http, httpUrlGenerator);
  }

  getAll(): Observable<Team[]> {
    return this.getAllTeams();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Team[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(team: Team): Observable<Team> {
    return this.http.post<any>(this.global.createTeamUrl, team)
    .pipe(
      mergeMap(teams=> combineLatest([
        Array.isArray(teams) && teams.length>0 ? of(teams[0]): of(teams),
        this.userService.collection$.pipe(filter(item=>!!item.loaded))
        ])),         
      map(([item, usersCollection]) => {
        let {teamManager, teamMembers, team_manager_id, team_members} = (item.data||item);
        team_members = team_members.split(',').map(id=>({id}));
        teamManager = usersCollection.entities[team_manager_id || teamManager.id || teamManager.user_id] || new UserItem({...teamManager});
        teamMembers = (team_members || teamMembers || []).map(member=> usersCollection.entities[member.id || member.user_id] || new UserItem({...member})).sort(sortComparerName); 
        return new Team({...team, ...(item.data||item), teamManager, teamMembers});
      }),
      catchError((error) => throwError(error))
    );
  }

  update(team: Update<Team>): Observable<any> {
    let updateTeamUrl = this.global.updateTeamUrl.replace('{team_id}', <string>team.id);
    return this.http.put<any>(updateTeamUrl, {...team.changes})
    .pipe(
      mergeMap(teams=> combineLatest([
        Array.isArray(teams) && teams.length>0 ? of(teams[0]): of(teams),
        this.userService.collection$.pipe(filter(item=>!!item.loaded))
        ])),         
      map(([item, usersCollection]) => {
        let {teamManager, teamMembers, team_manager_id, team_members} = item;
        team_members = team_members.split(',').map(id=>({id}));
        teamManager = usersCollection.entities[team_manager_id || teamManager.id || teamManager.user_id] || new UserItem({...teamManager});
        teamMembers = (team_members || teamMembers || []).map(member=> usersCollection.entities[member.id || member.user_id] || new UserItem({...member})).sort(sortComparerName); 
        return new Team({...team.changes ,...(item.data||item), teamManager, teamMembers});
      }),
      catchError((error) => throwError(error))
    );
  }

  delete(key: string | number): Observable<any> {
    let deleteTeamUrl = this.global.deleteTeamUrl.replace('{team_id}', <string>key);
    return this.http.delete(deleteTeamUrl)
    .pipe(
      catchError((error) => throwError(error))
    );
  }

  getAllTeams(): Observable<Team[]> {    
    return this.authService.currentUser$.pipe(
      switchMap( (currentUser) => {
        let {user} = currentUser || {};        
        return (!currentUser && !user) ? of([]) :
        this.http.get<any>(this.global.getTeamUrl.replace('{enterprise_id}', user.enterprise_id))
        .pipe(
          mergeMap(teams=> combineLatest([
            of(teams),
            this.userService.collection$.pipe(filter(item=>!!item.loaded))
            ])),         
          map(([teams, usersCollection]) => {
            return (teams || [] ).map(item => {
              let {teamManager, teamMembers} = item;
              teamManager = usersCollection.entities[teamManager.id || teamManager.user_id] || new UserItem({...teamManager});
              teamMembers = teamMembers.map(member=> usersCollection.entities[member.id || member.user_id] || new UserItem({...member})).sort(sortComparerName); 
              return new Team({...item, teamManager, teamMembers});
            }).sort(sortComparerName);      
          }))
      }),      
      catchError(err => of([]))
    );
  }
}

@Injectable()
export class TeamCollectionService extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory) {
    super('Team', elementsFactory);
  }

  get teams$(): Observable<Team[]> {
    return this.entities$;
  }

}
