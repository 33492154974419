
import * as _ from 'lodash';
export class User {
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    name?: string;
    token?: string;
    authData?: string;
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        this.name = [data.firstName, data.lastName].join(' ').trim();
    }
}

export class AuthUser {
    token: string;
    authData: string;
    user: UserItem;
    userRoles: UserItem[];
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        let {userRoles, user} = data;
        this.userRoles = (userRoles || []).map(user=>  new UserItem(user))
        if(user)
            this.user = new UserItem(user);
    }
}

export class UserItem {
    designation: string;
    email: string;
    employee_id: string;
    enterprise_id: string;
    firstname: string;
    is_firsttime: string;
    is_primary: string;
    lastname: string;
    master_id: string;
    phone_number: string;
    profile_photo: string;
    role_id: string;
    theme_id: string;
    user_id: string;
    user_name: string;
    created_date: Date;
    modified_date: Date;
    last_login: Date;
    name: string;
    preferences: any;
    maxSize: number = 100 * 1024 * 1024;

    constructor(data) {
        if(!data) return;
        for (const key in data) {
            if(key.toLowerCase().includes('date') || key.toLowerCase()==='last_login')
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
        this.preferences = (data.preferences && _.isString(data.preferences) && data.preferences!='[object Object]') ? JSON.parse(data.preferences) || {}: data.preferences || {};
        this.name = [data.firstname||'', data.lastname||''].join(' ').trim();
    }
}