import { EntityMetadataMap } from '@ngrx/data';
import { Enterprise } from '../../models/enterprise';

const entityMetadata: EntityMetadataMap = {
    Auth: {
        additionalCollectionState: {
            currentUser: null,
        }
    },
    Team: {
        selectId: selectTeamId,
        sortComparer: sortComparerName,
    },
    User: {
        selectId: selectUserId,
        sortComparer: sortComparerName
    },
    Category: {
        selectId: selectCategoryId,
    },
    Action: {
        selectId: selectActionId,
        sortComparer: sortAction
    },
    Document: {
        selectId: selectDocumentId
    },
    Milestone: {
        selectId: selectMilestoneId,
    },
    Goal: {
        selectId: selectGoalId,
    },
    Site: {
        additionalCollectionState: {
            siteInfo: null,
        }
    },
    Enterprise: {
        selectId: selectEnterpriseId,
        additionalCollectionState: {
            currentEnterprise: null,
        }
    },
    Calculator: {
        selectId : selectReportID,
        additionalCollectionState: {
            dictionary: {},
            questions:[]
        }
    },
    Common: {
        additionalCollectionState: {
            goalTypes: [],
            goalLevels: [],
            userRoles:[],
            priorities:[]
        }
    }
};

 
// because the plural of "Category" is not "Categorys"
const pluralNames = { Category: 'Categories', Auth: 'Auth' };

export function sortComparerName(a: {name: string}, b: {name: string}) {
    const lcA = a.name.toLowerCase() || '';
    const lcB = b.name.toLowerCase() || '';
    if (lcA < lcB) return -1;
    if (lcA === lcB) return 0;
    if (lcA > lcB) return 1;
}

export function sortAction(a: {sort_order: number}, b: {sort_order: number}) {
   return a.sort_order - b.sort_order;
}



export function selectId(item: { id: string }) {
    return item.id;
}

export function selectMilestoneId(item: { milestone_id: string, id: string  }) {
    return item.milestone_id || item.id;
}

export function selectGoalId(item: { goal_id: string, id: string  }) {
    return item.goal_id || item.id;
}

export function selectTeamId(item: { team_id: string, id: string  }) {
    return item.team_id || item.id;
}

export function selectActionId(item: { action_id: string, id: string  }) {
    return item.action_id || item.id;
}


export function selectCategoryId(item: { category_id: string, id: string  }) {
    return item.category_id || item.id;
}

export function selectDocumentId(item: { document_id: string, id: string  }) {
    return item.document_id || item.id;
}

export function selectEnterpriseId(item: { enterprise_id: string, id: string  }) {
    return item.enterprise_id || item.id;
}

export function selectUserId(item: { user_id: string, userId: string, id: string }) {
    return item.user_id || item.userId || item.id;
}

export function selectReportID(item: { report_id: string, id: string, reportID:string }) {
    return item.report_id || item.reportID|| item.id;
}



export const entityConfig = {
  entityMetadata,
  pluralNames
};
