import { Injectable } from '@angular/core';
import { EntityServicesBase, EntityServicesElements, EntityCacheDispatcher } from '@ngrx/data';
import { combineLatest, of, forkJoin, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, take, tap, debounceTime, concatMapTo, concatMap } from 'rxjs/operators';
import { TeamCollectionService } from '../services/team.service';
import { UserCollectionService } from '../services/user.service';
import { AuthCollectionService } from '../services/auth.service';
import { SiteCollectionService } from '../services/site.service';
import { CalculatorCollectionService } from '../services/calculator.service';
import { GoalCollectionService } from '../services/goal.service';
import { DocumentCollectionService } from './../services/document.service';
import { CommonCollectionService } from './../services/common.service';
import { ActionCollectionService } from './../services/action.service';
import { CategoryCollectionService } from './../services/category.service';
import { MilestoneCollectionService } from './../services/milestone.service';
import { EnterpriseCollectionService } from '../services/enterprise.service';
import { Constants } from '../../global/app.global.constants';


@Injectable({ providedIn: 'root' })
export class AppDataServices extends EntityServicesBase {
    loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        entityServicesElements: EntityServicesElements,
        public userService: UserCollectionService,
        public teamService: TeamCollectionService,
        public goalService: GoalCollectionService,
        public milestoneService: MilestoneCollectionService,
        public categoryService: CategoryCollectionService,
        public actionService: ActionCollectionService,
        public documentService: DocumentCollectionService,
        public commonService: CommonCollectionService,
        public enterpriseService: EnterpriseCollectionService,
        public siteService: SiteCollectionService,
        public calculatorService: CalculatorCollectionService,
        public authService: AuthCollectionService
    )
    {
        super(entityServicesElements);
        this.registerEntityCollectionServices([
            enterpriseService, userService, authService, teamService, siteService, calculatorService, commonService,
            goalService, milestoneService, categoryService, actionService]);
    }


    loadApp() {
       return this.loadPublic()
       .pipe(
            concatMap(([site, currentUser]) => {
                let admin = ((currentUser||{}).user||{}).role_id == Constants.roles.ADMINISTRATOR;
                return combineLatest([of(site), of(currentUser), currentUser ? this.loadSecure(admin) : of([])]);
            }),            
            debounceTime(200),
            tap(()=>this.loaded$.next(true)),
            catchError((err)=> {
                console.error(err);
                this.loaded$.next(true);
                return of([]);
            }),
        );
    }

    loadPublic() {
       return combineLatest([           
           this.siteService.load(),
           this.authService.load()
        ]);
    }

    loadSecure(admin:boolean) {
         return this.enterpriseService.load()         
        .pipe(            
            concatMap((res)=>combineLatest([of(res), admin? of([]): this.userService.load()])),
            concatMap((res)=>combineLatest([of(res),
                admin? of([]): this.teamService.load(),
                admin? of([]): this.goalService.load(),
                admin? of([]): this.milestoneService.load(),
            ])),
            concatMap((res)=>combineLatest([of(res),
                admin? of([]): this.commonService.load(),
                admin? of([]): this.categoryService.load(),
                admin? of([]): this.calculatorService.load(),
            ])),
            concatMap((res)=>combineLatest([of(res), admin? of([]): this.documentService.load()])), // dependent on users & teams
            concatMap((res)=>combineLatest([of(res), admin? of([]): this.actionService.load()])) // dependent on documents
        );
    }
}