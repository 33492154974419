import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpHeaders, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import sha1 from 'sha1';
import moment from 'moment';
import serverConfiguration from '../../assets/config/server-config.json';
import { GlobalService } from '../global/app.global.service';

@Injectable()
export class HttpInterceptors implements HttpInterceptor{
    public regularSecretKey: string = 'goalShaper_secretKey';
    constructor(private global: GlobalService){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
       // let currentUser = this.global.currentUserToken;
        var UTCDate = moment(new Date()).utc().format(); //'YYYY-MM-DD HH:mm:ss.SSS'
        var finalEpocTime = moment(UTCDate).unix();
        var keyCombination = this.regularSecretKey + finalEpocTime;
        var accessToken = sha1(keyCombination);
        let serverConfig = serverConfiguration;

        if(request.url.includes(serverConfig.host)) {
            if(this.global.currentUserToken){
                request = request.clone({
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `${this.global.currentUserToken}@@${accessToken}`,
                        'timestamp': finalEpocTime.toString()
                    })
                });
            }else{
                request = request.clone({
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `${accessToken}`,
                        'timestamp': finalEpocTime.toString()
                    })
                });
            }
        }

        return next.handle(request);
    }
}