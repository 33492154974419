import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./public/login/login.module').then(m => m.LoginModule) },
  { path: 'forgot-password', loadChildren: () => import('./public/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'landing', canActivate: [AuthGuard], loadChildren: () => import('./secure/landing/landing.module').then(m => m.LandingModule) },
  { path: '', canActivate: [AuthGuard], loadChildren: () => import('./secure/secure.module').then(m => m.SecureModule) },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
