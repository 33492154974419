import { Document } from './document';
import * as _ from 'lodash';
export class Action {
    action?: string;
	action_category_id?: string;
	action_id?: string;
	action_type?: number;
	assign_id?: string;
	assigned_by?: string;
	assigned_to?: any;
	assignment_comments?: string;
	category_name?: string;
	created_user_id?: string;
	description?: string;
	documents?: Document[] = [];
	enterprise_id?: string;
	goal_id?: string;
	is_assigned?: string;
	milestone_id?: string;
	modified_user_id?: string;
	objective_id?: string;
	priority_id?: string;
	priority_name?: string;
	remarks?: string;
	sort_order?: number;
	completed_date?: Date;
	created_date?: Date;
	scheduled_date?: Date;
	target_date?: Date;
    modified_date?: Date;
	status?:string = null;
	tasks?:any[] = [];
    constructor(data) {
		for (const key in data) {
			if(key.toLowerCase().includes('date'))
                this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else if(key=="documents") {
				if(_.isString(data[key])) {
					try {
						this[key]=JSON.parse(data[key]) || [];
						if(!Array.isArray(this[key])) {
							this[key] = [];
						}
						this[key]= (this[key]||[]).map(item=>new Document(item));
					} catch (error) {
						this[key] = [];
					}
				} else {
					this[key] = data[key] || [];
				}
				
			}
			else if(key=="tasks" || key=='assigned_to') {
				if(_.isString(data[key])) 
				{
					try {
						this[key]=JSON.parse(data[key]) || [];
						if(!Array.isArray(this[key])) {
							this[key] = [];
						}
					} catch (error) {
						this[key] = [];
					}
				} else {
					this[key] = data[key] || [];
				}
			}
			else if(key=='status')
				this[key] = data[key] && data[key] !='null' ? data[key] : 'null';
            else
                this[key] = data[key] && data[key] !='null' ? data[key] : null;
        }
    }
}
