import { UserItem } from './user';
import * as _ from 'lodash';
export class Team {
    team_id: string;
    color:  string;
    created_date: Date;
    description:  string;
    email:  string;
    enterprise_id:  string;
    modified_date: Date;
    name:  string;
    teamManager: UserItem;
    teamMembers: UserItem[];
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
    }
}