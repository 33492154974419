import * as _ from 'lodash';
export class Milestone {
    completion_date: Date;
    created_date:  Date;
    enterprise_id: String;
    goal_id: String;
    milestone_id: String;
    milestone: String;
    milestone_number: String;
    modified_date:  Date;
    target_date:  Date;
    created_user_id?: number;
    constructor(data) {
        for (const key in data) {
            if(key.toLowerCase().includes('date'))
            this[key] = (!data[key] || (_.isString(data[key]) && data[key].includes('1900'))) ? null : new Date(data[key]); 
            else
                this[key] = data[key];
        }
    }
}