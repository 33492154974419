import { Goal } from './../../models/goal';
import { EnterpriseService } from './../../services/enterprise.service';
import { GlobalService } from '../../global/app.global.service';
import { AuthCollectionService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityCacheDispatcher } from '@ngrx/data';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, distinct } from 'rxjs/operators';
import { Milestone } from '../../models/milestone';


@Injectable()
export class GoalDataService extends DefaultDataService<any> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private global:GlobalService, private service: EnterpriseService, private authService: AuthCollectionService ) {
    super('Goal', http, httpUrlGenerator);
  }

  getAll(): Observable<Goal[]> {
    return this.getGoals();
  }

  getWithQuery(params: string | QueryParams | any): Observable<Goal[] | any[] | any> {
    const {data} = params;
    return of(data || []);
  }

  add(goal: Goal): Observable<Goal> {
    return this.http.post<any>(this.global.createGoalUrl, goal)
    .pipe(
      map(item=> ({...goal, ...(item.data||item) })),
      catchError((error) => throwError(error))
    );
  }

  update(goal: Update<Goal>): Observable<any> {
    let url = this.global.updateGoalUrl.replace('{goal_id}', <string> goal.id);
    return this.http.put<any>(url, {...goal.changes})
    .pipe(
      map(item=> ({...goal.changes, ...(item.data||item)})),
      catchError((error) => throwError(error))
    );
  }

  delete(key: number | string): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let url = this.global.deleteGoalUrl;
        url = url.replace('{enterprise_id}', user.enterprise_id);
        url = url.replace('{goal_id}', <string>key);
        return (!currentUser && !user) ? of(false) : this.http.delete<any>(url)
      }),
      catchError((error) => throwError(error))
    );
  }

  getGoals(): Observable<Goal[]> {
    return  this.authService.currentUser$
    .pipe(
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        return (!currentUser && !user) ? of([]) : 
        this.getAllGoals(user.enterprise_id,user.user_id)
        .pipe(
          //distinct((item)=>item.goal_id),
          map(result => {
            return (result || [] ).reduce((acc,curr)=>{
              let filtered = acc.filter(item=>item.goal_id!=curr.goal_id);
              let item = acc.find(item=>item.goal_id==curr.goal_id) || curr;
              let milestones:any[] = item.milestones || [];
              if(curr.milestone) {
                milestones.push(new Milestone({...curr, 
                  status: curr.milestone_status, 
                  description: curr.milestone_description,
                  target_date: curr.milestone_target_date,
                  created_date: curr.milestone_created_date,
                  completed_date: curr.milestone_completed_date                
                }));
              }

              let progress = milestones.length >0 ?  ((milestones.filter(item=> item.status=='completed').length / milestones.length) * 100) : 0;
              return [...filtered, new Goal({...item, ...curr, milestones, progress,
                status: curr.goal_status, 
                description: curr.goal_description,
                target_date: curr.goal_target_date,
                created_date: curr.goal_created_date,
                completed_date: curr.goal_completed_date
              })];
            }, []);
          }))
      }),      
      catchError(err => of([]))
    );
  }

  getKPI(): Observable<any> {
    return  this.authService.currentUser$
    .pipe(
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let url = `${this.global.getKPI}/${user.enterprise_id}/${user.user_id}`;
        return (!currentUser && !user) ? of([]) : this.http.get<any>(url);
      }),
      map(item=> item && item.length>0 ? JSON.parse(item[0].kpi_data||'')||[] : item.kpi_data || []),      
      catchError(err => of([]))
    );
  }

  setKPI(kpi: any): Observable<Goal> {
    return  this.authService.currentUser$
    .pipe(
      switchMap( (currentUser) => {
        let {user} = currentUser || {}; 
        let url = `${this.global.setKPI}/${user.enterprise_id}/${user.user_id}`;
        return (!currentUser && !user) ? of([]) : this.http.post<any>(url, kpi);
      })
    );
  }

  getAllGoals(enterpriseId:any, userId:any): Observable<any> {
    /*let url = this.global.getAllGoalsUrl;
    url = url.replace('{enterprise_id}', enterpriseId);
    url = url.replace('{pageNumber}', '1');
    url = url.replace('{pageSize}', '10000');
    url = url.replace('{sortColumn}', 'goal_title');
    url = url.replace('{sortOrder}', 'asc');
    url = url.replace('{userId}', userId);*/
    let url = `${this.global.getFullGoals}/${enterpriseId}/${userId}`;
    return this.http.get<any>(url);
  }
}

@Injectable()
export class GoalCollectionService  extends EntityCollectionServiceBase<any> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private dataService: GoalDataService) {
    super('Goal', elementsFactory);
  }

  get goals$(): Observable<Goal[]> {
    return this.entities$;
  }

  getKPI(): Observable<any> {
    return this.dataService.getKPI();
  }

  setKPI(kpi: any): Observable<any> {
    return this.dataService.setKPI(kpi);
  }

}
