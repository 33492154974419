import { AppDataServices } from './../store/entity/entity-services';
import { Injectable,  } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor{
    constructor(private appDataServices: AppDataServices, private route: Router){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        return next.handle(request).pipe(catchError(err => {
            if(err.status === 401 || err.status == 403){
                if(!this.route.url.includes('/login')){
                    // auto logout if 401 response returned from api
                    this.appDataServices.authService.logout();
                }
            }
            return throwError(err);
        }))
    }
}