import { environment } from './../../environments/environment';
import { CommonModule } from '@angular/common';
import { enableProdMode, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './root-store.state';
import { RootStoreEffects } from './root-store.effects';
import { EntityStoreModule } from './entity/entity-store.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


let dev = [
  StoreDevtoolsModule.instrument({logOnly: environment.production, features: { persist: true }})
];
if (environment.production) {
  dev = [];
  enableProdMode();
}


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    EffectsModule.forRoot([RootStoreEffects]),    
    EntityStoreModule,
    ...dev,   
  ],  
})
export class RootStoreModule {
}
